import React, { FC, useEffect, useState } from "react"
import style from "./Header.module.scss"
import "animate.css"

import { Container } from "../../common/components/Container/Container"
import { scrollToElement } from "../../common/utils/scrollToElement"

import { Nav } from "./Nav/Nav"
import { Burger } from "./Burger/Burger"
import { SocialIcons } from "./SocialIcons/SocialIcons"
import { Select } from "./Select/Select"

export const Header: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false)
  const [burger, setBurger] = useState<boolean>(false)
  const [isScrolledDown, setIsScrolledDown] = useState<boolean>(false)
  const [scrollY, setScrollY] = useState<number>(0)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY

      setScrollY((prevScrollY) => {
        if (prevScrollY <= 0) {
          setIsScrolledDown(false)
        } else if (currentScrollY > prevScrollY) {
          setIsScrolledDown(true)
        } else {
          setIsScrolledDown(false)
          setIsMenuOpen(false)
          setBurger(false)
          setIsSelectOpen(false)
        }

        return currentScrollY
      })
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const onClickButtonHandler = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false)
    } else {
      setIsMenuOpen(true)
    }
  }

  return (
    <header className={`${style.header} ${isScrolledDown ? style.scrollDown : style.scrollUp}`}>
      <Container>
        <div className={style.wrapper}>
          <div className={style.logo}>
            <a onClick={() => scrollToElement("main")}>Fedrr</a>
          </div>

          <div
            className={
              isMenuOpen ? `animate__animated animate__fadeInDown animate__faster ${style.mobileMenu}` : style.mainMenu
            }
          >
            <Nav isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} burger={burger} setBurger={setBurger} />
          </div>

          <div className={style.icons}>
            <div className={style.mobileMenuButton}>
              <Burger burger={burger} setBurger={setBurger} onClick={onClickButtonHandler} />
            </div>

            <Select isSelectOpen={isSelectOpen} setIsSelectOpen={setIsSelectOpen} />

            <SocialIcons />
          </div>
        </div>
      </Container>
    </header>
  )
}
