import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { contactsReducer } from "../features/contacts/contacts.slice"
import { modalReducer } from "../common/components/Modal/modal.slice"
import { alertReducer } from "../common/components/Alert/alert.slice"

export const store = configureStore({
  reducer: {
    contacts: contactsReducer,
    modal: modalReducer,
    alert: alertReducer,
  },
})

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
