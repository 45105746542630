import React, { FC, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../common/hooks"
import { contactsThunks, setFormData } from "../contacts.slice"

import style from "./Contacts.module.scss"

import { Container } from "../../../common/components/Container/Container"
import { Animation } from "../../../common/components/Animation/Animation"
import { SocialIconsFooter } from "./SocialIconsFooter/SocialIconsFooter"
import { useLanguage } from "../../../common/translations/translations"
import { Button } from "../../../common/components/Button/Button"

export const Contacts: FC = () => {
  const { t } = useLanguage()
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector((state) => state.contacts.isLoading)
  const formData = useAppSelector((state) => state.contacts.formData)
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({})

  const isEmailValid = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {}
    if (!formData.name) newErrors.name = t("contacts_error_name_required")
    if (!formData.email) {
      newErrors.email = t("contacts_error_email_required")
    } else if (!isEmailValid(formData.email)) {
      newErrors.email = t("contacts_error_email_invalid")
    }
    if (!formData.message) newErrors.message = t("contacts_error_message_required")
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name } = event.target
    setTouched((prev) => ({ ...prev, [name]: true }))
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target
    dispatch(setFormData({ ...formData, [name]: value }))
  }

  const handleSubmitClick = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    sendMessage()
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && event.ctrlKey) {
      event.preventDefault()
      sendMessage()
    }
  }

  const sendMessage = () => {
    if (validateForm()) {
      dispatch(contactsThunks.addMessage(formData))
        .then(() => {
          const form = document.getElementById("contacts") as HTMLFormElement | null
          if (form) {
            const inputs = form.querySelectorAll("input, textarea")
            inputs.forEach((input) => (input as HTMLElement).blur())
          }
        })
        .then(() => setTouched({}))
    }
  }

  const isFormValid = formData.name && formData.email && formData.message && isEmailValid(formData.email)

  const errorMessages = Object.keys(errors)
    .filter((key) => touched[key])
    .map((key) => errors[key])

  useEffect(() => {
    validateForm()
  }, [formData, t])

  return (
    <section className={style.contactsBlock} id={"contacts"}>
      <Container>
        <div className={style.wrapper}>
          <Animation animation={"animate__fadeInUp"} threshold={0.1}>
            <div className={style.getInTouch}>
              <h2>{t("contacts_get_in_touch")}</h2>
              <p>{t("contacts_description_1")}</p>
              <p>{t("contacts_description_2")}</p>
              <h3>{t("contacts_contacts")}:</h3>
              <a href="mailto:info@fedrr.com" className={style.mailLink}>
                info@fedrr.com
              </a>
              <div className={style.icons}>
                <SocialIconsFooter />
              </div>
            </div>
          </Animation>

          <Animation animation={"animate__fadeInUp"} threshold={0.1}>
            <div className={style.form}>
              {isLoading && (
                <div className={style.loadingWrapper}>
                  <div className={style.loading}></div>
                </div>
              )}

              <h2>{t("contacts_write")}</h2>
              <form onSubmit={handleSubmitClick} onKeyDown={handleKeyDown}>
                <label htmlFor="name">{t("contacts_name")}:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.name && touched.name ? style.error : ""}
                />

                <label htmlFor="email">{t("contacts_email")}:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.email && touched.email ? style.error : ""}
                />

                <label htmlFor="message">{t("contacts_message")}:</label>
                <textarea
                  id="message"
                  name="message"
                  rows={5}
                  value={formData.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.message && touched.message ? style.error : ""}
                ></textarea>

                <div className={style.submitBlock}>
                  <div className={style.buttonWrapper}>
                    <Button type="submit" disabled={!isFormValid}>
                      {t("contacts_send")}
                    </Button>
                  </div>

                  <div className={style.info}>
                    {isFormValid ? (
                      <Animation animation={"animate__fadeInUp"} threshold={0.1} className={style.infoMessage}>
                        {t("contacts_use_ctrl_enter")}
                      </Animation>
                    ) : null}

                    {errorMessages.length ? (
                      <Animation animation={"animate__fadeInUp"} threshold={0.1} className={style.errorMessage}>
                        {errorMessages.map((error, index) => (
                          <Animation key={index} animation={"animate__fadeInUp"} threshold={0.1}>
                            {error}
                          </Animation>
                        ))}
                      </Animation>
                    ) : null}
                  </div>
                </div>
              </form>
            </div>
          </Animation>
        </div>
      </Container>
    </section>
  )
}
