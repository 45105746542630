import React from "react"
import style from "./SkillsLines.module.scss"
import { Line } from "./Line"
import { Animation } from "../../../common/components/Animation/Animation"
import { useLanguage } from "../../../common/translations/translations"

const skillsData = [
  { skill: "HTML", percentage: 90 },
  { skill: "CSS", percentage: 90 },
  { skill: "JavaScript", percentage: 90 },
  { skill: "TypeScript", percentage: 85 },
  { skill: "React", percentage: 85 },
  { skill: "Redux", percentage: 75 },
  { skill: "Redux Toolkit", percentage: 75 },
  { skill: "Node.js", percentage: 30 },
  // { skill: "React Native", percentage: 20 },
]

export const SkillsLines = () => {
  const { t } = useLanguage()

  return (
    <div className={style.skillLinesWrapper}>
      <Animation animation={"animate__fadeInUp"} threshold={0.1}>
        <h2>{t('resume_my_skills')}</h2>

        <div className={style.skillLinesBlock}>
          {skillsData.map((skill, i) => (
            <Line key={i} skill={skill.skill} percentage={skill.percentage} />
          ))}
        </div>
      </Animation>
    </div>
  )
}
