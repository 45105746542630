import React from "react"
import "./App.scss"

import { Header } from "../features/Header/Header"
import { Main } from "../features/Main/Main"
import { Skills } from "../features/Skills/Skills"
import { Projects } from "../features/Projects/Projects"
import { ContactsEmailJS } from "../features/contacts/Contacts/ContactsEmailJS"
import { Footer } from "../features/Footer/Footer"
import { Resume } from "../features/Resume/Resume"
import { AboutMe } from "../features/AboutMe/AboutMe"
import { Contacts } from "../features/contacts/Contacts/Сontacts"

function App() {
  return (
    <>
      <Header />
      <Main />
      <AboutMe />
      <Skills />
      <Resume />
      {/*<Projects />*/}
      {/*<ContactsEmailJS />*/}
      <Contacts />
      <Footer />
    </>
  )
}

export default App
