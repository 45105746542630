import React, { FC, useEffect, useState } from "react"
import style from "./Nav.module.scss"
import { scrollToElement } from "../../../common/utils/scrollToElement"
import { useLanguage } from "../../../common/translations/translations"

type NavPropsType = {
  isMenuOpen: boolean
  setIsMenuOpen: (isMenuOpen: boolean) => void
  burger: boolean
  setBurger: (burger: boolean) => void
}

export const Nav: FC<NavPropsType> = (props) => {
  const [active, setActive] = useState<string>("")
  const { t } = useLanguage()

  const closeMenu = () => {
    props.setIsMenuOpen(false)
    props.setBurger(false)
  }

  const onChangeActiveLink = (value: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    setActive(value)
    closeMenu()
    scrollToElement(value)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActive(entry.target.id)
          }
        })
      },
      { threshold: 0.3 },
    )

    const sections = document.querySelectorAll("section")
    sections.forEach((section) => observer.observe(section))

    return () => observer.disconnect()
  }, [])

  const navData = [
    { link: "main", name: t("main") },
    { link: "about", name: t("aboutMe") },
    { link: "skills", name: t("skills") },
    { link: "resume", name: t("resume") },
    // { link: 'projects', name: 'Projects' },
    { link: "contacts", name: t("contacts") },
  ]

  return (
    <div>
      {!props.isMenuOpen ? (
        <div className={style.nav}>
          {navData.map((a, i) => {
            return (
              <a
                key={i}
                className={active === a.link ? style.active : style.link}
                href={`#${a.link}`}
                onClick={onChangeActiveLink(a.link)}
              >
                {a.name}
              </a>
            )
          })}
        </div>
      ) : (
        <div className={style.navMobile}>
          {navData.map((a, i) => {
            return (
              <a
                key={i}
                className={active === a.link ? style.activeMobile : style.linkMobile}
                href={`#${a.link}`}
                onClick={onChangeActiveLink(a.link)}
              >
                {a.name}
              </a>
            )
          })}
        </div>
      )}
    </div>
  )
}
