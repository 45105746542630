import React, { FC } from "react"
import style from "./Footer.module.scss"

import { useAppDispatch } from "../../common/hooks"
import { toggleModalOpen } from "../../common/components/Modal/modal.slice"

import { Container } from "../../common/components/Container/Container"
import { Animation } from "../../common/components/Animation/Animation"

export const Footer: FC = () => {
  const dispatch = useAppDispatch()

  return (
    <footer className={style.footer}>
      <Container>
        <Animation animation={"animate__fadeInUp"} threshold={0.1}>
          <div className={style.wrapper}>
            <div className={style.copyright}>
              {`© ${new Date().getFullYear()} `}
              <a target={"_blank"} href="https://www.linkedin.com/in/feddorovich/" rel="noreferrer">
                Sergey Fedorovich
              </a>
              {`. All Rights Reserved.`}
            </div>
            <div
              className={style.status}
              onClick={() => dispatch(toggleModalOpen({ contentKey: "footer", header: "Lose Yourself to Dance" }))}
            >
              Lose Yourself to Dance
            </div>
          </div>
        </Animation>
      </Container>
    </footer>
  )
}
