import React, { FC, ReactNode } from "react"
import s from "./ComponentHeader.module.scss"

type PropsType = {
  children: ReactNode
}

export const ComponentHeader: FC<PropsType> = ({ children }) => {
  return <h2 className={s.header}>{children}</h2>
}
