import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { createAppAsyncThunk } from "../../common/utils/createAppAsyncThunk"
import { contactsApi, FormData } from "./contacts.api"
import { showAlert } from "../../common/components/Alert/alert.slice"

type ContactsState = {
  isLoading: boolean
  formData: FormData
}

const initialState: ContactsState = {
  isLoading: false,
  formData: {
    name: "",
    email: "",
    message: "",
  },
}

const addMessage = createAppAsyncThunk(
  "contacts/addMessage",
  async (
    data: {
      name: string
      email: string
      message: string
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      dispatch(setLoading(true))
      const res = await contactsApi.sendMessage(data)
      dispatch(showAlert({ text: gettext("contacts_sent_successfully") }))
      dispatch(setFormData({ name: "", email: "", message: "" }))

      return res.data
    } catch (error: any) {
      if (error?.response?.status === 429) {
        const timeToWait = error.response.data.timeToWait
        dispatch(showAlert({ text: `${gettext("contacts_error_2")} ${timeToWait} ${gettext("contacts_seconds")}` }))
      } else {
        dispatch(showAlert({ text: gettext("contacts_error_1") }))
      }

      const errorMessage = error instanceof Error ? error.message : "Unknown error"

      return rejectWithValue(errorMessage)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

const slice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setFormData: (state, action: PayloadAction<{ name: string; email: string; message: string }>) => {
      state.formData = action.payload
    },
  },
})

export const { setLoading, setFormData } = slice.actions
export const contactsReducer = slice.reducer
export const contactsThunks = { addMessage }
