import React, { FC, useEffect, useState } from "react"
import style from "./Alert.module.scss"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { hideAlert } from "./alert.slice"
import { IoClose } from "react-icons/io5"

export const Alert: FC = () => {
  const dispatch = useAppDispatch()

  const [progress, setProgress] = useState<number>(100)

  const isShown = useAppSelector((state) => state.alert.isShown)
  const message = useAppSelector((state) => state.alert.message.text)
  const status = useAppSelector((state) => state.alert.message.status)

  useEffect(() => {
    if (!message) return

    const duration = 8000
    let startTime: number

    const updateProgress = () => {
      const elapsedTime = Date.now() - startTime
      const remainingTime = Math.max(duration - elapsedTime, 0)
      setProgress((remainingTime / duration) * 100)
    }

    const timer = setTimeout(() => {
      dispatch(hideAlert())
    }, duration)

    startTime = Date.now()
    const interval = setInterval(updateProgress, 100)

    return () => {
      clearTimeout(timer)
      clearInterval(interval)

      setTimeout(() => {
        setProgress(100)
      }, 200)
    }
  }, [message])

  return (
    <div className={`${style.wrapper} ${isShown ? style.show : ""}`}>
      <div className={style.body}>
        <div className={status === "success" ? style.success : status === "error" ? style.error : ""}>{message}</div>
        <div onClick={() => dispatch(hideAlert())} className={style.btn}>
          <IoClose />
        </div>
      </div>

      <div className={style.progressBar} style={{ width: `${progress}%` }} />
    </div>
  )
}
