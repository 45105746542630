import React, { useState, useEffect, useRef } from "react"
import styles from "./Select.module.scss"
import { useLanguage } from "../../../common/translations/translations"
import { Link } from "react-router-dom"
import { languages } from "../../../common/translations/translationsData"

type SelectProps = {
  isSelectOpen: boolean
  setIsSelectOpen: (burger: boolean) => void
}

export const Select: React.FC<SelectProps> = ({ isSelectOpen, setIsSelectOpen }) => {
  const { setLanguage, currentLanguage } = useLanguage()

  const [selectedOption, setSelectedOption] = useState<string>(currentLanguage)

  const handleSelect = (option: string) => {
    setSelectedOption(option)
    setIsSelectOpen(false)
    setLanguage(option)
  }

  const selectRef = useRef<HTMLDivElement | null>(null)

  const handleClickOutside = (event: Event) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsSelectOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  return (
    <div
      ref={selectRef}
      className={`${styles.customSelect} ${isSelectOpen ? styles.open : ""}`}
      onMouseEnter={() => setIsSelectOpen(true)}
      onMouseLeave={() => setIsSelectOpen(false)}
    >
      <div
        className={styles.selectedValue}
        onClick={() => setIsSelectOpen(!isSelectOpen)}
      >
        {selectedOption || "Select an option"}
        <span className={styles.arrow} />
      </div>

      {isSelectOpen && (
        <div className={`${styles.options} ${styles.open}`}>
          {languages.map((option, index) => (
            <div key={index} onClick={() => handleSelect(option)}>
              <Link to={`/${option}`}>{option}</Link>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
