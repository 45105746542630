import { createSlice } from "@reduxjs/toolkit"

type AlertState = {
  isShown: boolean
  message: AlertMessage
}

export type AlertMessage = { text: string, status?: "success" | "error" }

const initialState: AlertState = {
  isShown: false,
  message: { text: "" },
}

const slice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showAlert: (state, action) => {
      state.isShown = true
      state.message = { text: action.payload.text, status: action.payload.status }
    },
    hideAlert: (state) => {
      state.isShown = false
      state.message = { text: "" }
    },
  },
})

export const { showAlert, hideAlert } = slice.actions
export const alertReducer = slice.reducer
