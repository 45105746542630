import React, { useState } from "react"
import s from "./FooterModal.module.scss"

export const FooterModal = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <div className={s.footerModal}>
      {!isLoaded && <div className={s.loading} />}
      <iframe
        style={{
          borderRadius: "12px",
          marginTop: "4px",
          display: isLoaded ? "block" : "none",
        }}
        src="https://open.spotify.com/embed/track/5CMjjywI0eZMixPeqNd75R?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        title="Spotify Track Embed"
        onLoad={() => setIsLoaded(true)}
      ></iframe>
    </div>
  )
}
