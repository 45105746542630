import React, { FC, ReactNode } from "react"
import style from "./Container.module.scss"

type ContainerType = {
  children: ReactNode
}

export const Container: FC<ContainerType> = ({ children }) => {
  return <div className={style.container}>{children}</div>
}
