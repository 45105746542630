import React, { CSSProperties, FC, ReactNode, useState } from "react"
import s from "./Animation.module.scss"
import "animate.css"
import { useInView } from "react-intersection-observer"

type AnimationType = {
  children?: ReactNode
  animation:
    | "animate__fadeIn"
    | "animate__fadeInUp"
    | "animate__fadeInLeft"
    | "animate__fadeInRight"
    | "animate__zoomIn"
    | "animate__zoomInRight"
  threshold: number
  className?: string
  style?: CSSProperties
}

export const Animation: FC<AnimationType> = ({ children, animation, threshold, className, style }) => {
  const [active, setActive] = useState(false)

  const { ref, inView } = useInView({
    threshold: threshold,
  })

  React.useEffect(() => {
    if (inView) {
      setActive(true)
    }
  }, [inView])

  return (
    <div ref={ref} className={`${active ? `animate__animated ${animation} ${className}` : s.opacity}`} style={style}>
      {children}
    </div>
  )
}
