import React, { FC, useEffect, useRef } from "react"
import s from "./Modal.module.scss"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { closeModal } from "./modal.slice"
import { IoClose } from "react-icons/io5"
import { FooterModal } from "./Modals/FooterModal/FooterModal"

export const Modal: FC = () => {
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector((state) => state.modal.isOpen)
  const header = useAppSelector((state) => state.modal.header)
  const contentKey = useAppSelector((state) => state.modal.contentKey)
  const modalRef = useRef<HTMLDivElement>(null)

  const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1
  const isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
  const isMobile = window.innerWidth <= 768

  const renderContent = () => {
    switch (contentKey) {
      case "footer":
        return <FooterModal />
      default:
        return null
    }
  }

  const keyDownHandler = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      event.stopPropagation()
      event.preventDefault()
      dispatch(closeModal())
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      dispatch(closeModal())
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", keyDownHandler)
      document.addEventListener("mousedown", handleClickOutside)
      document.body.style.overflow = "hidden"
      document.body.style.touchAction = "none"

      if (!isMobile) {
        if (isDarkTheme) {
          document.documentElement.style.setProperty("--backgroundBefore", "#212529")
          document.documentElement.style.setProperty("--backgroundAfter", "#212529")
        } else {
          document.documentElement.style.setProperty("--backgroundBefore", "#ffffff")
          document.documentElement.style.setProperty("--backgroundAfter", "#ffffff")
        }

        if (!isFirefox) {
          document.body.style.paddingRight = "10px"
          document!.querySelector("header")!.style.paddingRight = "10px"
          document!.getElementById("scrollToTop")!.style.display = "none"
        }
      }
    } else {
      setTimeout(() => {
        document.body.style.overflow = ""
        document.body.style.touchAction = ""
        document.body.style.paddingRight = ""
        document!.querySelector("header")!.style.paddingRight = ""
        document!.getElementById("scrollToTop")!.style.display = "flex"
      }, 400)
    }

    return () => {
      document.removeEventListener("keydown", keyDownHandler)
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isOpen])

  return (
    <div className={`${s.wrapper} ${isOpen ? s.open : ""}`}>
      <div className={s.modal} tabIndex={-1} ref={modalRef}>
        <div className={s.header}>
          <div>{header ? header : ""}</div>
          <div>
            <IoClose onClick={() => dispatch(closeModal())} className={s.icon} />
          </div>
        </div>

        {renderContent()}
      </div>
    </div>
  )
}
